import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/_services/auth.service';
import { TokenService } from 'src/app/auth/_services/token.service';

export const AuthGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService)
  const _tokenService = inject(TokenService)
  const router = inject(Router);
  let token = await _tokenService.getToken()
  if (token != "" && token != undefined && token != null) {
    router.navigate([authService.INITIAL_PATH]);
    return true
  }
  return true
};


