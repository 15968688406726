import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
// import { TypePaymentModel } from 'src/app/core/_models/type-payment.model';
import { ChangePaswComponent } from '../change-pasw.component';
import { ChangePasswordModel } from 'src/app/auth/_models/change-password.model';

@Injectable({
  providedIn: 'root'
})
export class ChangePaswService {

  api = environment.URL_API

  http = inject(HttpClient)

  constructor(private dialogService: DialogService) { }

  save(obj: ChangePasswordModel){
    return this.http.post(this.api + '/auth/login/change',obj)
  }

  openChangePasePageModal(userId: string){
    return this.dialogService.open(ChangePaswComponent,{
      header: "Change Pase",
      data: userId,
      // width: '70%'
      styleClass: 'layout-modal layout-modal-md',
      // height: '100%'
      // width: 'auto !important'
    })
  }
}
