import { Injectable, inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "src/app/auth/_services/auth.service";


export namespace AppGuard {
    export const canActivate = (
        // route: ActivatedRouteSnapshot, 
        // state: RouterStateSnapshot
    ) => {
        const authService = inject(AuthService);
        const router = inject(Router);

        return authService.isLoggedIn$()
        return authService.isLoggedIn$().then( isLogin => {
            return true
        }, e => {
            router.navigate(['/login']);
            return false
        })
       
    }

}