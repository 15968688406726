
import { InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { config } from "src/app/config";
import { UserAuthModel } from "../_models/user.model";
import { JwtAuthStrategy } from "./jwt.auth.strategy";
import { SessionAuthStrategy } from "./session.auth.strategy";



export interface AuthStrategy<T> {
  doLoginUser(data: T): void;

  doLogoutUser(): void;

  getCurrentUser(): Promise<UserAuthModel>;
}

export const AUTH_STRATEGY = new InjectionToken<AuthStrategy<any>>(
  "AuthStrategy"
);

export const authStrategyProvider = {
  provide: AUTH_STRATEGY,
  deps: [HttpClient],
  useFactory: (http: HttpClient) => {
    switch (config.auth) {
      case "session":
        return new SessionAuthStrategy(http);
      case "token":
        return new JwtAuthStrategy();
    }
  },
};