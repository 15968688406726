import { environment } from "src/environments/environment.development";

interface Config {
    // [key: string]: string;
    apiUrl: string,
    adminUrl: string,
    authUrl: string,
    auth: "session" | "token";
}
export const config: Config = {
    apiUrl: environment.URL_API  + "/api",
    adminUrl: environment.URL_API  + "/api/admin",
    authUrl: environment.URL_API  + "",
    auth: "token",
};