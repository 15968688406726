import { HttpClient } from "@angular/common/http";
import { AuthStrategy } from "./auth.strategy";
import { Observable, of, tap } from "rxjs";
import { UserAuthModel } from "../_models/user.model";

export class SessionAuthStrategy implements AuthStrategy<UserAuthModel> {
    private loggedUser?: UserAuthModel;
  
    constructor(private http: HttpClient) {}
  
    doLoginUser(user: UserAuthModel): void {
      this.loggedUser = user;
    }
  
    doLogoutUser(): void {
      this.loggedUser = undefined;
    }
  
    getCurrentUser(): Promise<UserAuthModel> {
      return new Promise((res, rej) => {
        // res(undef)
      })
      // if (this.loggedUser) {
      //   return of(this.loggedUser);
      // } else {
      //   return this.http
      //     .get<UserModel>(`${config['authUrl']}/user`)
      //     .pipe(tap((UserModel) => (this.loggedUser = UserModel)));
      // }
    }
  }