import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "./containers/login/login.component";
import { SignupComponent } from "./containers/signup/signup.component";
import { AuthGuard } from "../_guard/auth.guard";
// import { SignupComponent } from "./containers/signup/signup.component";


const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "signup",
    component: SignupComponent,
    canActivate: [AuthGuard],
  },
//   {
//     path: "confirm",
//     component: ConfirmComponent,
//     canActivate: [AuthGuard],
//   },
//   {
//     path: "password",
//     component: PasswordComponent,
//     canActivate: [AuthGuard],
//   },
//   {
//     path: "recover",
//     component: RecoverComponent,
//     canActivate: [AuthGuard],
//   },
//   {
//     path: "oauth",
//     component: OAuthComponent,
//   },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}