import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from 'src/app/auth/_models/login.model';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';

import { UserAuthModel } from '../../_models/user.model';
import { Message } from 'primeng/api';
import { TokenService } from '../../_services/token.service';
import { AUTH_STRATEGY, AuthStrategy } from '../../_services/auth.strategy';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  messages: Message[] | undefined;

  get f(): any { return this.loginForm.controls }
  
  get filledInput(): boolean {
		return this.layoutService.config().inputStyle === 'filled';
	}

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  });

  loading = false
  
  constructor(
      private layoutService: LayoutService,
      private formBuilder: FormBuilder,
      private authService: AuthService,
      private router: Router,
      private tokenService: TokenService,
      @Inject(AUTH_STRATEGY) private auth: AuthStrategy<any>
    ) {}

  ngOnInit(): void {
  }

  login() {
    if(!this.loginForm.valid){
      this.loginForm.markAllAsTouched()
      return
    }
    this.messages = []
    this.loading = true
    const loginModel: LoginModel = {
      email: this.f.email.value,
      password: this.f.password.value,
    };
    this.authService.login(loginModel).then( (user: UserAuthModel) => {
      this.tokenService.setToken(user.token??"")
      
      this.loading = false
      this.router.navigate([
        this.authService.getInitialPathForRole(user.rol_id??0),
      ])
    }, e => {
      this.tokenService.clearToken()
      this.messages = [{ severity: 'error', summary: 'error', detail: e.error }];
      this.loading = false
    })
  }

  goToRegisterPage(){
    this.router.navigate([this.authService.SIGNUP_PATH])
  }
}
