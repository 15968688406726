<div class="surface-ground h-screen w-screen flex align-items-center justify-content-center" [ngClass]="{'p-input-filled': filledInput}">
    <div class="surface-card py-7 px-5 sm:px-7 shadow-2 flex flex-column w-11 sm:w-30rem" style="border-radius:14px">
        <h1 class="font-bold text-2xl mt-0 mb-2">PROXIMITY</h1>
        <p class="text-color-secondary mb-2">Sign Up</p>
    
    <p-messages *ngIf="messages" [(value)]="messages" [enableService]="false" [closable]="false" class="mt-0"></p-messages>
    <form [formGroup]="form" (ngSubmit)="signup()" class="flex flex-column">
        <span class="p-input-icon-left mb-4">
            <i class="pi pi-user"></i>
            <input id="email" formControlName="email" type="text" pInputText placeholder="Email" 
            class="w-full"
            [ngClass]="{'ng-invalid ng-dirty' : form.controls.email.touched && form.controls.email.errors}">
        </span>

        <span class="p-input-icon-left mb-4">
            <i class="pi pi-key"></i>
            <input formControlName="password" type="password" pInputText 
                placeholder="Password" 
                class="w-full"
                [ngClass]="{'ng-invalid ng-dirty' : form.controls.password.touched && form.controls.password.errors}">
            <small class="ng-dirty ng-invalid text-red-500" *ngIf="form.controls.password.touched && form.controls.password.errors">{{form.controls.password.errors['password']}} {{form.controls.password.errors['minlength'] | json}} {{form.controls.password.errors['maxlength'] | json}}</small>
        </span>

        <span class="p-input-icon-left mb-4">
            <i class="pi pi-key"></i>
            <input formControlName="confirmpassword" type="password" pInputText 
                placeholder="Confirm password" 
                class="w-full"
                [ngClass]="{'ng-invalid ng-dirty' : form.controls.confirmpassword.touched && form.controls.confirmpassword.errors}">
            <small class="ng-dirty ng-invalid text-red-500" *ngIf="form.controls.confirmpassword.touched && form.controls.confirmpassword.errors">{{form.controls.confirmpassword.errors['password']}} {{form.controls.confirmpassword.errors['minlength'] | json}} {{form.controls.confirmpassword.errors['maxlength'] | json}}</small>
        </span>

        <button [loading]="loading" type="submit" pButton pRipple label="Sign Up" class="mb-4"></button>
    </form>

        <!-- <span class="text-color-secondary text-center mb-4">or sign in with below</span> -->

        <!-- <div class="flex gap-4 align-items-center justify-content-center"> -->
            <!-- <a href="https://www.facebook.com" class="inline-flex flex-shrink-0 w-3rem h-3rem justify-content-center align-items-center surface-ground border-circle">
                <i class="pi pi-facebook text-2xl text-color"></i>
            </a>
            <a href="https://www.twitter.com" class="inline-flex flex-shrink-0 w-3rem h-3rem justify-content-center align-items-center surface-ground border-circle">
                <i class="pi pi-twitter text-2xl text-color"></i>
            </a>
            <a href="https://www.google.com" class="inline-flex flex-shrink-0 w-3rem h-3rem justify-content-center align-items-center surface-ground border-circle">
                <i class="pi pi-google text-2xl text-color"></i>
            </a>
            <a href="https://www.github.com" class="inline-flex flex-shrink-0 w-3rem h-3rem justify-content-center align-items-center surface-ground border-circle">
                <i class="pi pi-github text-2xl text-color"></i>
            </a> -->
        <!-- </div> -->
    </div>
</div>

<!-- <app-config [minimal]="true"></app-config> -->
