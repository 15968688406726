import { Injectable, inject } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})

export class TokenService {
    private readonly TOKEN_KEY = "auth_token_md"
    service = inject(AuthService)

    getToken(): string | null {
        return sessionStorage.getItem(this.TOKEN_KEY)
    }

    setToken(token: string): void {
        sessionStorage.setItem(this.TOKEN_KEY, token)
    }

    clearToken(): void {
        sessionStorage.removeItem(this.TOKEN_KEY)
    }
}