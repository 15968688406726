import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

// import { UserModel } from 'src/app/core/_models/user.model';
// import { config } from 'src/app/core/config';
import { Observable, tap } from 'rxjs';
// import { LoginModel } from 'src/app/auth/_models/login.model';
// import { SignupModel } from '../_models/signup.model';
import { AUTH_STRATEGY, AuthStrategy } from './auth.strategy';
import { UserAuthModel } from '../_models/user.model';
import { SignupModel } from '../_models/signup.model';
import { config } from 'src/app/config';
import { LoginModel } from '../_models/login.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public readonly INITIAL_PATH = "/app";
  public readonly DASHBOARD_PATH = "/app";
  // public readonly ADMIN_PATH = "/admin";
  public readonly LOGIN_PATH = "/login";
  public readonly SIGNUP_PATH = "/signup";
  // public readonly CONFIRM_PATH = "/confirm";

  constructor(
    private router: Router,
    private http: HttpClient,
    // private cacheService: CacheService,
    @Inject(AUTH_STRATEGY) private auth: AuthStrategy<any>
  ) { }

  getInitialPathForRole(role: number): string {
    return role == 1 ? this.DASHBOARD_PATH : this.INITIAL_PATH;
  }

  signup(user: SignupModel): Observable<void> {
    return this.http.post<any>(`${config.authUrl}/signup`, user);
  }

  confirm(email: string, code: string): Observable<void> {
    return this.http.post<any>(`${config.authUrl}/confirm?`, { email, code });
  }

  login(loginModel: LoginModel): Promise<UserAuthModel> {
    return new Promise((res,rej) => {
      this.http.post<UserAuthModel>(`${config.authUrl}/login`, loginModel).subscribe({
        next: (user:UserAuthModel) => {
          this.auth.doLoginUser(user)
          return res(user)
        },
        error: (e) => {
          return rej(e)
        }
      })
    })

  }
  
  

  logout() {
    return new Promise((res,rej) => {
      this.http.get<any>(`${config.authUrl}/logout`).subscribe({
        next: () => {
          this.doLogoutUser()
          res(true)
        },
        error: (e) => {
          rej(false)
        }
      })
    })
  }

  // isLoggedIn$(): Observable<boolean> {
  //   return this.auth.getCurrentUser().pipe(
  //     map((user) => !!user),
  //     catchError(() => of(false))
  //   );
  // }
  isLoggedIn$(): Promise<boolean> {
    return new Promise((res, rej) => {
      this.auth.getCurrentUser().then((user: UserAuthModel) => {
        return res(!!user)
      }, e => {
        rej(false)
      })
    })
  }

  getCurrentUser$(): Promise<UserAuthModel> {
    return this.auth.getCurrentUser();
  }

  // getUserRole$(): Observable<string> {
  //   return this.auth.getCurrentUser().pipe(map((user) => user.role));
  // }

  // getUserEmail$(): Observable<string> {
  //   return this.auth.getCurrentUser().then(map((user) => user.email??""));
  // }

  doLogoutAndRedirectToLogin() {
    this.doLogoutUser();
    this.router.navigate(["/login"]);
  }

  // logoutAuth0() {
  //   return this.logout().subscribe(() => {
  //     window.location.href = `${auth0.url}/logout?client_id=${auth0.clientId}&returnTo=${auth0.returnUrl}`;
  //   });
  // }

  // isAuth0User(user: UserModel): boolean {
  //   return user.id.startsWith("auth0");
  // }

  private doLogoutUser() {
    // this.cacheService.pruneAll();
    this.auth.doLogoutUser();
  }

}
