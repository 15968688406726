import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { SignupModel } from '../../_models/signup.model';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-register',
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit {
  
  messages: Message[] | undefined;

  get f(): any { return this.form.controls }
  
  get filledInput(): boolean {
		return this.layoutService.config().inputStyle === 'filled';
	}

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]),
    confirmpassword: new FormControl('', [Validators.required,Validators.minLength(4), Validators.maxLength(20)])
  })
  
  loading = false

  constructor(
      private layoutService: LayoutService,
      private formBuilder: FormBuilder,
      private authService: AuthService,
      private router: Router,
      // @Inject(AUTH_STRATEGY) private auth: AuthStrategy<any>
    ) { }

  ngOnInit(): void {
    // this.form = this.formBuilder.group({
    //   email: new FormControl('', [Validators.email]),
    //   password: ['', Validators.required],
    //   confirmpassword: ['', Validators.required]
    // });
  }

  signup() {
    if(!this.form.valid){
      this.form.markAllAsTouched()
      return
    }
    
    if(this.f.password.value.trim() !=  this.f.confirmpassword.value.trim()){
      this.form.controls.password?.setErrors({"password":"Passwords are not the same"})
      this.form.controls.confirmpassword?.setErrors({"password":"Passwords are not the same"})
      return
    }

    this.loading = true
    this.messages = []
    const user: SignupModel = {
      email: this.f.email.value.trim(),
      password: this.f.password.value.trim(),
    };
    this.authService.signup(user).subscribe({
      next: () => {
        this.loading = false
        this.router.navigate([this.authService.LOGIN_PATH])
      },
      error: (e) => {
        this.loading = false
        this.messages = [{ severity: 'error', summary: 'error', detail: e.error }];
      }
    })
  }
}