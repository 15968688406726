<div class="surface-ground h-screen w-screen flex align-items-center justify-content-center" [ngClass]="{'p-input-filled': filledInput}">
    <div class="surface-card py-7 px-5 sm:px-7 shadow-2 flex flex-column w-11 sm:w-30rem" style="border-radius:14px">
        <h1 class="font-bold text-2xl mt-0 mb-2">LOGIN</h1>
        <!-- <p class="text-color-secondary mb-4">Welcome to the <strong>PROXIMITY</strong>, where the magic happens, sign in to continue.</p> -->

    <p-messages *ngIf="messages" [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="flex flex-column">
        <span class="p-input-icon-left mb-4">
            <i class="pi pi-user"></i>
            <input formControlName="email" type="text" pInputText 
                placeholder="Email" 
                class="w-full"
                [ngClass]="{'ng-invalid ng-dirty' : loginForm.controls.email.touched && loginForm.controls.email.errors}">
        </span>

        <span class="p-input-icon-left mb-4">
            <i class="pi pi-key"></i>
            <input formControlName="password" type="password" pInputText 
                placeholder="Password" 
                class="w-full"
                [ngClass]="{'ng-invalid ng-dirty' : loginForm.controls.password.touched && loginForm.controls.password.errors}">
        </span>

        <button [loading]="loading" type="submit" pButton pRipple label="Sign In" class="mb-2"></button>
    </form>
       
    </div>
</div>