import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule, LOCATION_INITIALIZED } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthRoutingModule } from "./auth-routing.module";
import { LoginComponent } from "./containers/login/login.component";
import { authStrategyProvider } from "./_services/auth.strategy";
import { LoginModel } from "./_models/login.model";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { InputTextModule } from "primeng/inputtext";
import { AppConfigModule } from "../layout/config/config.module";
import { AuthService } from "./_services/auth.service";
import { MessagesModule } from 'primeng/messages';
import { SignupComponent } from "./containers/signup/signup.component";

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent
  ],
//   exports: [ForRolesDirective],
  imports: [
    CommonModule,
    RouterModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    
    // LoginRoutingModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    AppConfigModule,
    MessagesModule
    // LoginModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
    authStrategyProvider
  ],
})
export class AuthModule {}