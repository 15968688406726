import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { AuthService } from '../auth/_services/auth.service';
import { TokenService } from '../auth/_services/token.service';
import { Router } from '@angular/router';
import { UserAuthModel } from '../auth/_models/user.model';
import { ChangePaswService } from '../demo/components/change-pasw/service/change-pass.service';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [
        ChangePaswService,
        DialogService
    ]
})
export class AppTopBarComponent {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    _tokenService = inject(TokenService)
    _authService = inject(AuthService)
    _paswService = inject(ChangePaswService)

    user: any

    constructor(public layoutService: LayoutService, private router: Router) { }

    async ngOnInit() {
        this.user = await this._authService.getCurrentUser$()
    }

    changePasw(){
        this._paswService.openChangePasePageModal(this.user?.id??"")
    }

    logout(){
        this._authService.logout().then((res) => {
            this._tokenService.clearToken()
            this.router.navigate([this._authService.LOGIN_PATH]);
        }, e => {
            this._tokenService.clearToken()
            alert(e.error)
        })
        
    }
}
