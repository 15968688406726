import { Component, OnInit, inject, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/_services/auth.service';
import { ChangePaswService } from './service/change-pass.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserModel } from 'src/app/auth/_models/user.model copy';
import { ChangePasswordModel } from 'src/app/auth/_models/change-password.model';



@Component({
  selector: 'app-plan-member',
  templateUrl: './change-pasw.component.html'
})
export class ChangePaswComponent implements OnInit{
  
  _ref = inject(DynamicDialogRef);
  
  _service = inject(ChangePaswService)
  _authService = inject(AuthService)

  user?: UserModel
  cols: any[] = [];

  loading = false
  error?: string
  

  form = new FormGroup({
    curr: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
    password: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
    confirm_password: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
    id: new FormControl(),
  });

  constructor(){

    this._authService.getCurrentUser$().then( (user: any) => {
      this.user = user
      this.form.controls.id.setValue(this.user.id)
    }, e => {

    })
  }

  ngOnInit(): void {
    
  }

  save(){
    this.loading = true
    this._service.save(this.form.value as ChangePasswordModel).subscribe({ 
      next: (res) => {
        this.loading = false
        this.close()
      },
      error: (e) => {
        this.loading = false
        alert(e.error)
      }
    })
  }

  close(){
    this._ref.close()
  }
}
