
import { AuthStrategy } from "./auth.strategy";
import { TokenModel } from "../_models/token.model";
import { UserAuthModel } from "../_models/user.model";


export class JwtAuthStrategy implements AuthStrategy<TokenModel> {
    private readonly JWT_TOKEN = "JWT_TOKEN";
  
    doLoginUser(token: any): void {
      localStorage.setItem(this.JWT_TOKEN, JSON.stringify(token));
    }
  
    doLogoutUser(): void {
      localStorage.removeItem(this.JWT_TOKEN);
    }
  
    getCurrentUser(): Promise<UserAuthModel> {
      return new Promise((res, rej) => {
        const token = this.getToken();
        if (token) {
          return res(JSON.parse(token??""));
        } else {
          return rej();
        }
      })
    }
  
    getToken() {
      return localStorage.getItem(this.JWT_TOKEN);
    }
  }