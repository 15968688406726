import { RouterModule, Routes, provideRouter, withComponentInputBinding } from '@angular/router';
import { NgModule } from '@angular/core';
// import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AppGuard } from './_guard/app.guard';


const routes: Routes = [
    {
        path: "", redirectTo: "/login", pathMatch: "full",
    },
    {
        path: 'app', 
        canActivate: [AppGuard.canActivate],
        component: AppLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
            { path: 'projects', loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectModule) },
            { path: 'entities', loadChildren: () => import('./pages/entity/entity.module').then(m => m.EntityModule) },
            { path: 'download', loadChildren: () => import('./pages/download/download.module').then(m => m.DownloadModule) },
        ]
    },
    { path: 'change', loadChildren: () => import('./demo/components/change-pasw/change-pasw.module').then(m => m.ChangePaswModule) },
]
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload', useHash: false })
    ],
    providers: [
        provideRouter(routes, withComponentInputBinding())
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
