<span style="color: red;" *ngIf="error">{{error}}</span>
    <form [formGroup]="form" (ngSubmit)="save()">
        <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-12">
                <label for="curr" pTooltip="Click to proceed">Actual contraseña</label>
                <input
                    id="curr" 
                    type="password"
                    pInputText
                    formControlName="curr"
                    [ngClass]="{'ng-invalid ng-dirty' : form.controls.curr.touched && form.controls.curr.errors}"/>
                <small class="ng-dirty ng-invalid text-red-500" *ngIf="form.controls.curr.touched && form.controls.curr.errors">{{form.controls.curr.errors | json}}</small>
            </div>
            <div class="field col-12 md:col-12">
                <label for="password" pTooltip="Click to proceed">Nueva contraseña</label>
                <input
                    id="password" 
                    type="password"
                    pInputText
                    formControlName="password"
                    [ngClass]="{'ng-invalid ng-dirty' : form.controls.password.touched && form.controls.password.errors}"/>
                <small class="ng-dirty ng-invalid text-red-500" *ngIf="form.controls.password.touched && form.controls.password.errors">{{form.controls.password.errors | json}}</small>
            </div>
            <div class="field col-12 md:col-12">
                <label for="confirm_password" pTooltip="Click to proceed">Confirmar contraseña</label>
                <input
                    id="confirm_password" 
                    type="password"
                    pInputText
                    formControlName="confirm_password"
                    [ngClass]="{'ng-invalid ng-dirty' : form.controls.confirm_password.touched && form.controls.confirm_password.errors}"/>
                <small class="ng-dirty ng-invalid text-red-500" *ngIf="form.controls.confirm_password.touched && form.controls.confirm_password.errors">{{form.controls.confirm_password.errors | json}}</small>
            </div>
        </div>
        <p-divider styleClass="p-divider-dotted"></p-divider>
        <div class="flex justify-content-end align-items-end gap-2">
            <p-button  pRipple 
                type="button"
                styleClass="p-button-outlined w-8rem" 
                label="Cancelar" icon="pi pi-fw pi-times" (click)="close()"
                [disabled]="loading">&nbsp;</p-button>
            <p-button  pRipple 
                type="submit"
                styleClass="p-button-primary w-8rem"
                label="Guardar" icon="{{loading ? 'pi pi-spin pi-spinner' : 'pi pi-fw pi-check'}}" 
                [disabled]="loading">&nbsp;</p-button>
        </div>
    </form>
