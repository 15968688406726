<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <!-- <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo"> -->
        <span>MDRYT-MMAYA</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button> -->
        <button class="p-link">
            <!-- <i class="pi pi-user"></i> -->
            <span>{{user?.entity}} - {{user?.email}}</span>
        </button> 
        
        <button class="p-link layout-topbar-button"  (click)="changePasw()">
            <i class="pi pi-key"></i>
            <span>pass</span>
        </button>
        <button class="p-link layout-topbar-button"  (click)="logout()">
            <i class="pi pi-power-off"></i>
            <span>Salir</span>
        </button>
    </div>
</div>